import React, { useEffect, useRef } from "react";
import { EnumCapturedResultItemType, EnumImagePixelFormat, OriginalImageResultItem } from "dynamsoft-core";
import { EnumBarcodeFormat } from "dynamsoft-barcode-reader";
import { CameraEnhancer, CameraView } from "dynamsoft-camera-enhancer";
import { CapturedResultReceiver, CaptureVisionRouter } from "dynamsoft-capture-vision-router";
import { MultiFrameResultCrossFilter } from "dynamsoft-utility";
import "./cvr.ts"; // import side effects. The license, engineResourcePath, so on.
import "./VideoCapture.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getNextPage } from "../../../redux/slices/user.slice.ts";
import { useIngestBarcodeDataMutation, useLogEventMutation } from "../../../api/api.ts";
import barcodeOverlay from "../../../assets/barcode.png";
import { Base64 } from "js-base64";
import ExpirationTimer from "../../ExpirationTimer/ExpirationTimer.tsx";
import { getCompanyConfig } from "../../../utils/getCompanyConfig.ts";
import { ImageManager } from "dynamsoft-utility";
import { headingStyles } from "../../CaptureMessages/CaptureMessages.tsx";
import { Badge } from "react-bootstrap";
import ErrorBoundaryWrapper from "../../ErrorBoundary/ErrorBoundaryWrapper.tsx";
import { setBarcodeScanResults } from "../../../redux/slices/demo.slice.ts";
import { APIVersion } from "../../../assets/apiVersionEnums.ts";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import createLogEventBody from "../../../utils/createLogEventBody.js";
import LoadingPage from "../../../pages/LoadingPage.tsx";

type VideoCaptureProps = {
    nextPath?: string;
};

const BarcodeImageAndDecode: React.FC<VideoCaptureProps> = ({ nextPath }) => {
    const apiVersion = window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION;

    const uiContainer = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const { t } = useTranslation();

    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language } = user;
    const url = nextPath ? `/${nextPath}?${urlParams}` : `/${getNextPage(captureRequirements, "barcode")}?${urlParams}`;
    const navigate = useNavigate();

    const [sendData, sendDataResponse] = useIngestBarcodeDataMutation();
    const [logEvent] = useLogEventMutation();
    const hasCapturedBarcode = useRef(false);
    const hideCountdownTimer = getCompanyConfig("hideCountdownTimer");

    const pInit = useRef(
        null as Promise<{
            cameraView: CameraView;
            cameraEnhancer: CameraEnhancer;
            router: CaptureVisionRouter;
        }> | null,
    );
    const pDestroy = useRef(null as Promise<void> | null);

    // Listens for response from sending the barcode and navigates to the next page when successfully sent to server
    useEffect(() => {
        if (sendDataResponse.isSuccess) {
            dispatch(setBarcodeScanResults(sendDataResponse.data.payload));
            logEvent(createLogEventBody(CustJourneyCodes.captureBarcode.imageCaptured.status));
            navigate(url);
        }
        if (sendDataResponse.isError) {
        }
    }, [sendDataResponse.isSuccess, sendDataResponse.error]);

    const hideDropDowns = () => {
        const cameraSelElement = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-sel-camera") || null;
        const resolutionSelElement =
            document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-sel-resolution") || null;
        if (cameraSelElement) {
            (cameraSelElement as any).style.display = "none";
        }
        if (resolutionSelElement) {
            (resolutionSelElement as any).style.display = "none";
        }
    };

    const processFile = (file: any, barcodeData: any) => {
        let objectURL = URL.createObjectURL(file);

        let src_image = new Image();

        src_image.onload = () => {
            let canvas = document.createElement("canvas");
            canvas.height = src_image.height;
            canvas.width = src_image.width;
            let ctx = canvas.getContext("2d");
            ctx?.drawImage(src_image, 0, 0);
            let jpegDataUrl = canvas.toDataURL("image/jpeg");
            const base64Image = jpegDataUrl.split(",")[1];
            const base64Barcode = Base64.encode(barcodeData);

            const transactionBody = {
                barcodeData: base64Barcode,
                backImage: base64Image,
            };
            sendData(transactionBody);
        };
        src_image.src = objectURL;
    };

    let cameraEnhancer: CameraEnhancer, cameraView: CameraView, router: CaptureVisionRouter;

    const init = async () => {
        try {
            cameraView = await CameraView.createInstance();
            cameraEnhancer = await CameraEnhancer.createInstance(cameraView);

            await cameraEnhancer?.setResolution({
                width: 1920,
                height: 1080,
            });

            // adding code to hide highligh toverlay
            let dbrDrawingLayer = cameraView.getDrawingLayer(2);
            dbrDrawingLayer.setVisible(false);

            uiContainer.current!.innerText = "";
            uiContainer.current!.append(cameraView.getUIElement());

            router = await CaptureVisionRouter.createInstance();
            router.setInput(cameraEnhancer);

            // Create a `CameraEnhancer` instance for camera control and a `CameraView` instance for UI control.

            let settings = await router.getSimplifiedSettings("ReadDenseBarcodes");
            settings.capturedResultItemTypes |= EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE;
            settings.barcodeSettings.barcodeFormatIds = EnumBarcodeFormat.BF_PDF417;
            settings.barcodeSettings.expectedBarcodesCount = 1;
            settings.barcodeSettings.grayscaleTransformationModes = [2, 0, 0, 0, 0, 0, 0, 0];
            settings.barcodeSettings.localizationModes = [16, 8, 2, 0, 0, 0, 0, 0];
            settings.barcodeSettings.minResultConfidence = 0;
            await router.updateSettings("ReadDenseBarcodes", settings);
            await router.startCapturing("ReadDenseBarcodes");

            const resultReceiver = new CapturedResultReceiver();

            resultReceiver.onCapturedResultReceived = (result) => {
                let barcodes = result.items.filter((item) => item.type === EnumCapturedResultItemType.CRIT_BARCODE);
                if (barcodes?.length > 0 && !hasCapturedBarcode.current) {
                    hasCapturedBarcode.current = true;
                    let image = result.items.filter(
                        (item) => item.type === EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE,
                    )[0] as OriginalImageResultItem;
                    const resultBarcode = (barcodes[0] as any).text;

                    const imgManager = new ImageManager();
                    imgManager.saveToFile(image.imageData, "withBarcode.png").then((file: any) => {
                        processFile(file, resultBarcode);
                    });
                    router.stopCapturing();
                }
            };
            router.addResultReceiver(resultReceiver);

            const filter = new MultiFrameResultCrossFilter();
            filter.enableResultCrossVerification(EnumCapturedResultItemType.CRIT_BARCODE, true);
            filter.enableResultDeduplication(EnumCapturedResultItemType.CRIT_BARCODE, true);
            filter.setDuplicateForgetTime(EnumCapturedResultItemType.CRIT_BARCODE, 3000);
            await router.addResultFilter(filter);

            const cameras = await cameraEnhancer?.getAllCameras();
            if (cameras && cameras?.length) {
                const backCam = cameras.find((cam) => cam?.label?.toLowerCase().includes("back camera(hd)"));
                if (backCam) {
                    // if we have an ultrawide, we are on a iOS device, so set it.
                    await cameraEnhancer?.selectCamera(backCam);
                }
                // if we don't, let dynamsoft do the work of choosing the camera
            }

            await cameraEnhancer.open();
            cameraEnhancer.setPixelFormat(EnumImagePixelFormat.IPF_ABGR_8888);

            if (!window.__RUNTIME_CONFIG__.REACT_APP_DEBUG_DYNAMSOFT) {
                setTimeout(() => {
                    hideDropDowns();
                    const message = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-msg-poweredby") || null;
                    if (message) {
                        (message as any).style.display = "none";
                    }
                }, 150);
            }
            logEvent(createLogEventBody(CustJourneyCodes.captureBarcode.captureComponentInit.status));

            return {
                cameraView,
                cameraEnhancer,
                router,
            };
        } catch (ex: any) {
            let errMsg: string;

            if (ex.message.includes("denied permission")) {
                navigate(`/camera-denied?token=${token}&version=${routerVersion}&language=${language}`);
            }
            if (ex.message.includes("network connection error")) {
                errMsg =
                    "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
            } else if (ex.message.includes("Permission denied")) {
                errMsg = "Camera permissions were denied.";

                navigate(`/camera-denied?token=${token}&version=${routerVersion}&language=${language}`);
            } else {
                errMsg = ex.message || ex;
            }

            logEvent(createLogEventBody(CustJourneyCodes.captureBarcode.captureComponentInitError.status, ex));

            throw ex;
        }
    };

    const destroy = async (): Promise<void> => {
        if (pInit.current) {
            const { cameraView, cameraEnhancer, router } = await pInit.current;
            router.dispose();
            cameraEnhancer.dispose();
            cameraView.dispose();
        }
    };

    useEffect(() => {
        (async () => {
            // In 'development', React runs setup and cleanup one extra time before the actual setup in Strict Mode.
            if (pDestroy.current) {
                await pDestroy.current;
                pInit.current = init();
            } else {
                pInit.current = init();
            }
        })();

        return () => {
            (async () => {
                await (pDestroy.current = destroy());
            })();
        };
    }, []);

    return (
        <div>
            <ErrorBoundaryWrapper>
                {!hideCountdownTimer && !sendDataResponse.isLoading &&(
                    <div className='position-fixed z-3 top-0 end-0 me-2'>
                        <ExpirationTimer shouldRender={true} />
                    </div>
                )}
                {hasCapturedBarcode.current || sendDataResponse.isLoading ? (
                    <LoadingPage></LoadingPage>
                ) : (
                    <>
                        <div
                            className='text-center position-absolute'
                            style={{
                                width: "100vw",
                                zIndex: 100,
                                top: "7vh",
                            }}
                        >
                            <Badge className='p-2 fs-5' bg={headingStyles.backgroundColor} style={headingStyles}>
                                {t("captureMessages.videoDecode")}
                            </Badge>
                        </div>

                        <div ref={uiContainer} id='div-ui-container' style={{ height: "92vh", width: "100vw" }}></div>
                        <img
                            className='position-absolute'
                            src={barcodeOverlay}
                            role='none'
                            aria-hidden='true' // this image is purely decorative
                            alt={t("captureMessages.barcodeOverlay")}
                            style={{
                                opacity: window.__RUNTIME_CONFIG__.REACT_APP_BARCODE_OVERLAY_ORIENTATION === "none" ? "0.0" : "0.2",
                                height: "100px",
                                width: "90%",
                                zIndex: 100,
                                left: "5%",
                                top: "calc(50% - 50px)",
                                transform: window.__RUNTIME_CONFIG__.REACT_APP_BARCODE_OVERLAY_ORIENTATION === "landscape" ? "rotate(90deg)" : "none",
                            }}
                        />
                        <div
                            className='text-center position-absolute'
                            style={{
                                width: "100vw",
                                zIndex: 100,
                                bottom: "25vh",
                            }}
                        >
                            <Badge className='p-2 fs-5 text-wrap' bg={headingStyles.backgroundColor} style={headingStyles}>
                                {t("captureMessages.flipIDToBack")}
                            </Badge>
                        </div>
                    </>
                )}
            </ErrorBoundaryWrapper>
        </div>
    );
};

export default BarcodeImageAndDecode;
